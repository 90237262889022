import { create } from 'zustand';

// Auth store for JWT Contents for further use

export const authStore = create((set) => ({
  authState: {
    profileId: '',
    name: '',
    organizationName: '',
    email: '',
    license: '',
    items: [],
    claims: [],
    roles: [],
  },
  setAuthState: (authState) => set({ authState }),
}));

// TODO document props
