import jwt_decode from 'jwt-decode';

export function setToken(userToken) {
  localStorage.setItem('token', JSON.stringify(userToken));
}

export function getToken() {
  if (typeof window !== 'undefined') {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.access_token;
  }
}

export function removeToken() {
  localStorage.removeItem('token');
}

export function getUserDataFromToken(token) {
  if (token != undefined) {
    const data = jwt_decode(token);
    const userData = JSON.parse(data?.sub?.replace(/'/g, '"'));
    return userData;
  } else {
    return 'No Data Available';
  }
}

import { authStore } from '@context/authStore';

export function jwtToStore(jwt) {
  const decoded = jwt_decode(jwt);
  const {
    sub,
    name,
    organizationName,
    email,
    license,
    claims: { items, claims, roles },
  } = decoded;
  const profileId = sub;

  const authState = {
    profileId,
    name,
    organizationName,
    email,
    license,
    items,
    claims,
    roles,
  };

  authStore((state) => state.setAuthState(authState));

  // TODO doesn't need to return anything really, rm this and testpage.
  return {
    profileId: profileId,
    name: name,
    organizationName: organizationName,
    email: email,
    license: license,
    items: items,
    claims: claims,
    roles: roles,
  };
}

export function decodeJwt(jwt) {
  const decoded = jwt_decode(jwt);
  const {
    sub,
    name,
    bundles,
    claims,
    items,
    is_validated,
    is_active,
    email,
    subscription,
    organizations,
  } = decoded;

  return {
    profileId: sub,
    name: name,
    bundles: bundles,
    claims: claims,
    items: items,
    is_validated: is_validated,
    is_active: is_active,
    email: email,
    subscription: subscription,
    organizations: organizations,
  };
}
