import { classNames } from '@helpers/classNames';
import Footer from './Footer';
import Navbar from './Navbar';

export default function Layout(props) {
  const layoutStyles =
    props.isNavigating || props.screenSize
      ? 'flex min-h-screen flex-col'
      : 'flex h-screen flex-col';
  return (
    <div className={classNames(layoutStyles)}>
      <Navbar />
      <main className="grow bg-accent-3">{props.children}</main>
      <Footer />
    </div>
  );
}
