import Container from '@elements/Container';
import logo from '@elements/Logo';

export default function CompactHeader() {
  const logoData = logo;

  return (
    <>
      <Container variant="primary">
        <div className="flex justify-between py-4">
          <div>
            <logoData.bouwzo.svg className="w-[72px] text-primary-dark" />
          </div>
          <a href="/" className="font-secondary text-primary-dark underline">
            Terug naar home
          </a>
        </div>
      </Container>
    </>
  );
}
