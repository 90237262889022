import CompactFooter from './CompactFooter';
import CompactHeader from './CompactHeader';

export default function CompactLayout({ children }) {
  return (
    <div className="flex h-screen flex-col justify-between">
      <CompactHeader />
      <main className="mb-auto grow">{children}</main>
      <CompactFooter />
    </div>
  );
}
