import LoginForm from '@forms/LoginForm';

export default function LoginSection({ isInvite, routerQuery, readerUrl }) {
  return (
    <div className="h-full bg-accent-3 sm:flex sm:items-center sm:justify-center">
      <div className="h-full py-20">
        <div className="container mx-auto max-w-2xl sm:px-4 md:max-w-2xl">
          <div className="bg-accent-3">
            <h1 className="px-4 pb-6 font-primary text-5xl font-light text-primary-dark sm:px-0">
              Inloggen
            </h1>
            <div className="mx-4 bg-functional-contrast p-5 sm:mx-0 sm:p-6 md:p-8 lg:px-20 lg:py-8">
              <LoginForm isInvite={isInvite} readerUrl={readerUrl} {...routerQuery} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
