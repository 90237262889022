import Icon from '@elements/Icon';

const data = {
  questions: {
    label: 'Vragen?',
    description: 'Neem contact op met onze klantenservice',
  },
  mail: {
    label: 'klantenservice@bouwzo.nl',
    description: 'Antwoord binnen 24 uur',
  },
  phone: {
    label: '010-2065960',
    description: 'Beschikbaar van ma t/m vr 08:30 tot 16:30',
  },
};

export default function CompactFooter() {
  return (
    <div className="bg-white px-4 py-8">
      <div className="container mx-auto max-w-7xl">
        <div className="flex flex-col justify-between gap-8 font-secondary text-primary-dark md:flex-row">
          <div className="flex flex-col gap-1 md:gap-2">
            <div className="text-lg font-black">{data.questions.label}</div>
            <div>{data.questions.description}</div>
          </div>
          <div className="flex flex-col gap-1 md:gap-2">
            <a
              className="flex items-center gap-3 text-lg font-black hover:text-accent"
              href={`mailto:${data.mail.label}`}
            >
              <Icon iconName="mail" color={'inheret'}></Icon>
              {data.mail.label}
            </a>
            <div>{data.mail.description}</div>
          </div>
          <div className="flex flex-col gap-1 md:gap-2">
            <a
              className="flex items-center gap-3 text-lg font-black hover:text-accent"
              href={`tel:${data.phone.label}`}
            >
              <Icon iconName="phone" color={'inheret'}></Icon>
              {data.phone.label}
            </a>
            <div>{data.phone.description}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
